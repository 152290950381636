#portfolio {
  background: #c9c9c9;
  padding-top: 40px;
  padding-bottom: 60px;
}
#portfolio h1 {
  font-family: "Bebas Neue", cursive;
  text-decoration: underline;
  font-size: 32px;
  color: #2b2b2b;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 60px;
}
#portfolio h2 {
  font-family: "Bebas Neue", cursive;
  text-decoration: underline;
  font-size: 28px;
  color: #2b2b2b;
  font-weight: lighter;
  letter-spacing: 1px;
}
.portfolio-item .item-wrap p {
  line-height: 1.7;
  display: block;
  padding: 0 15px;
}
.portfolio-wrapper .columns {
  margin-bottom: 36px;
}
.img-container {
  position: relative;
  width: 50%;
  margin: 0 auto;
}
.item-img-links a,
.item-img-links a:visited {
  color: #2b2b2b;
}
.item-img-links a:hover {
  color: rgb(197, 169, 153);
}

.item-img {
  border-radius: 8px;
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
  -webkit-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.64);
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.64);
}

.overlay {
  border-radius: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #2b2b2b;
}

.img-container:hover .overlay {
  opacity: 0.8;
  cursor: pointer;
}
.item-wrap {
  border-bottom: 1px solid rgba(54, 54, 54, 0.64);
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
@media (min-width: 767px) {
  .portfolio-item .item-wrap {
    display: flex;
  }
  .item-img {
    width: 30vw;
    margin: 20% 0;
  }
  .overlay {
    height: 72%;
    margin: 20% 0;
  }
  .item-img-links {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .portfolio-wrapper .columns {
    margin-bottom: 56px;
  }
}
@media (min-width: 1024px) {
  .item-img {
    width: 450px;
  }
  .project-description {
    font-size: 18px;
  }

  .item-img-links {
    padding: 20px;
  }
  .portfolio-wrapper .columns {
    margin-bottom: 36px;
  }
}
