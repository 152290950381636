header .banner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}
header .banner-text h1 {
  font-size: 90px/1.9em;
  color: rgb(255, 255, 255);
  letter-spacing: -2px;
  margin: 0 auto 18px auto;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}
header .banner-text h2 {
  font-size: 18px/1.9em;
  color: #a8a8a8;
  margin: 0 auto;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}
#nav-wrap ul,
#nav-wrap li,
#nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
#nav-wrap {
  font-size: 1vh;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;

  position: fixed;
}
ul#nav {
  min-height: 42px;
  width: auto;
}
#nav-wrap > a.mobile-btn {
  display: none;
}

ul#nav {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(0, 0, 0, 0.7);
}
ul#nav li a {
  color: white;
  text-decoration: none;
}
ul#nav li a:hover {
  color: rgb(207, 160, 133);
  cursor: pointer;
}

/* header .social {
  margin: 24px 0;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
} */
header .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}
header .social li a {
  color: rgb(197, 169, 153);
}
header .social li a:hover {
  color: rgb(221, 221, 221);
}
#nav-wrap {
  font: 12px "opensans-bold", sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}
header {
  color: rgb(0, 0, 0);
  position: relative;
  height: 1000px;
  min-height: 500px;
  width: 100%;
  background: #161415 url(../images/Background.png) no-repeat top center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}
header .social {
  margin: 24px 0;
  padding: 0;
  font-size: 3px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}
header .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

header .banner-text h2 {
  font-size: 18px/1.9em;
  color: #000000;
  margin: 0 auto;
  width: 70%;
}
.avatar {
  position: relative;
  max-height: 27vw;
  min-height: 200px;
  width: auto;
  border-radius: 50%;
  border: 2px solid rgb(197, 169, 153);
  padding: 20px;
  /* top: 3em; */
  margin: 3em 0;
}

header .banner {
  padding-bottom: 12px;
  padding-top: 6px;
}
header .banner-text h1 {
  letter-spacing: 4px;
  font-weight: lighter;
  font-family: "Bebas Neue", cursive;
  color: rgb(197, 169, 153);
  font-size: 78px;
}
header .banner-text h2 {
  margin: 0 auto;
  color: rgb(221, 221, 221);
  text-shadow: -1px -1px 0 #2b2b2b, 1px -1px 0 #2b2b2b, -1px 1px 0 #2b2b2b,
    1px 1px 0 #2b2b2b;
  font-size: 22px;
  font-weight: lighter;
  letter-spacing: 1px;
  width: 85%;
}
header .banner-text hr {
  width: 80%;
  margin: 18px auto;
}

header .social {
  font-size: 24px;
  line-height: 32px;
}

header .social li {
  border: 1px solid white;
  border-radius: 50%;
  padding: 6px 12px;

  margin: 0 8px;
}
header .social li:hover {
  cursor: pointer;
}

header .scrolldown {
  display: none;
}
@media (max-width: 767px) {
  #nav-wrap {
    z-index: 100;
    font: 12px;
    background: transparent !important;
    letter-spacing: 1.5px;
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
  }
  #nav-wrap > a {
    width: 48px;
    height: 48px;
    text-align: left;
    background-color: rgb(197, 169, 153);
    position: relative;
    border-left: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    float: right;

    font: 0/0 a;
    text-shadow: none;
    color: transparent;

    position: relative;
    top: 0px;
  }

  #nav-wrap > a:before,
  #nav-wrap > a:after {
    position: absolute;
    border: 2px solid #fff;
    top: 35%;
    left: 25%;
    right: 25%;
    content: "";
  }
  #nav-wrap > a:after {
    top: 60%;
  }

  #nav-wrap:not(:target) > a:first-of-type,
  #nav-wrap:target > a:last-of-type {
    display: block;
  }

  #nav-wrap ul#nav {
    height: auto;
    display: none;
    clear: both;
    width: auto;
    float: right;

    position: relative;
    right: 0;
  }
  #nav-wrap > a.mobile-btn {
    display: none;
  }

  #nav-wrap:target > ul#nav {
    display: block;
    padding: 30px 20px 48px 20px;
    background: rgba(0, 0, 0, 0.7);
    margin: 0;
    clear: both;
  }

  ul#nav li {
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0 4%;
    text-align: left;
    border-bottom: 1px solid rgb(197, 169, 153);
    border-bottom-style: dotted;
  }

  ul#nav li a {
    display: block;
    margin: 0;
    padding: 0;
    margin: 12px 0;
    line-height: 1.7;
    border: none;
  }
  ul#nav li a:visited {
    color: white;
  }
  ul#nav li a {
    color: white;
    text-decoration: none;
  }
}
