footer {
  padding-top: 28px;
  margin-bottom: 28px;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-container h2 {
  font-size: 24px;
}

.footer-container ul {
  display: flex;
}
.footer-container ul li {
  margin: 0 10px;
  flex-shrink: inherit;
}

footer a,
footer a:visited {
  color: #525252;
}
footer a:hover,
footer a:focus {
  color: rgb(197, 169, 153);
}

/* social links */
footer .social-links {
  list-style: none;
  font-size: 26px;
}
/* footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #f06000;
} */

/* footer .social-links li:first-child {
  margin-left: 0;
} */

/* Go To Top Button */
#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px;
}
#go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #525252;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  color: #fff;
  font-size: 21px;
  line-height: 60px;
  border-radius: 100%;
}
#go-top a:hover {
  background-color: #000000;
}
