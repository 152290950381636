#about {
  background: #2b2b2b;
  padding-top: 66px;
  padding-bottom: 66px;
  overflow: hidden;
}

#about a,
#about a:visited {
  color: #fff;
}
#about a:hover,
#about a:focus {
  color: #11abb0;
}

#about h2 {
  font-family: "Bebas Neue", cursive;
  text-decoration: underline;
  font-size: 32px;
  color: rgb(197, 169, 153);
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 12px;
  text-align: left;
}
#about p {
  letter-spacing: 0.7px;
  font-size: 16px;
  text-align: left;
  line-height: 1.7;
  color: #aaaaaa;
}
#about .profile-pic {
  width: auto;
  height: 150px;
  border-radius: 100%;
  border: 1px solid rgb(197, 169, 153);
  padding: 13px;
}
